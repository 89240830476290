/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { DEFAULT_LANG, LANG } from '@17live/app/constants';

import { shouldPolyfill as shouldDatetimeformat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldDisplaynames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldNumberformat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPluralrules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldRelativetimeformat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import enTranslationMessages from './translations/en.json';
import zhTWTranslationMessages from './translations/zh-Hant.json';

/**
 * @name addLocaleData
 * @see https://formatjs.io/docs/polyfills/intl-numberformat/
 * @see https://formatjs.io/docs/polyfills/intl-datetimeformat/
 * @see https://formatjs.io/docs/polyfills/intl-pluralrules/
 * @see https://formatjs.io/docs/polyfills/intl-relativetimeformat/
 * @see https://formatjs.io/docs/polyfills/intl-displaynames/
 * @param {*} locale
 * @returns
 */
export const addLocaleData = async locale => {
  const unsupportedDatetimeformatLocale = shouldDatetimeformat(locale);
  const unsupportedDisplaynamesLocale = shouldDisplaynames(locale);
  const unsupportedNumberformatLocale = shouldNumberformat(locale);
  const unsupportedPluralrulesLocale = shouldPluralrules(locale);
  const unsupportedRelativetimeformatLocale = shouldRelativetimeformat(locale);

  if (unsupportedDatetimeformatLocale) {
    await import('@formatjs/intl-datetimeformat/polyfill-force');
    // Parallelize CLDR data loading
    const dataPolyfills = [
      import('@formatjs/intl-datetimeformat/add-all-tz'),
      import(
        /* webpackInclude: /(en|zh|ja|ko)\.js$/, webpackChunkName: "datetimeformat-locale-data.[request]" */
        `@formatjs/intl-datetimeformat/locale-data/${unsupportedDatetimeformatLocale}`
      ),
    ];
    await Promise.all(dataPolyfills);
  }

  if (unsupportedDisplaynamesLocale) {
    await import('@formatjs/intl-displaynames/polyfill-force');
    await import(
      /* webpackInclude: /(en|zh|ja|ko)\.js$/, webpackChunkName: "displaynames-locale-data.[request]" */
      `@formatjs/intl-displaynames/locale-data/${unsupportedDisplaynamesLocale}`
    );
  }

  if (unsupportedNumberformatLocale) {
    await import('@formatjs/intl-numberformat/polyfill-force');
    await import(
      /* webpackInclude: /(en|zh|ja|ko)\.js$/, webpackChunkName: "numberformat-locale-data.[request]" */
      `@formatjs/intl-numberformat/locale-data/${unsupportedNumberformatLocale}`
    );
  }

  if (unsupportedPluralrulesLocale) {
    await import('@formatjs/intl-pluralrules/polyfill-force');
    await import(
      /* webpackInclude: /(en|zh|ja|ko)\.js$/, webpackChunkName: "pluralrules-locale-data.[request]" */
      `@formatjs/intl-pluralrules/locale-data/${unsupportedPluralrulesLocale}`
    );
  }

  if (unsupportedRelativetimeformatLocale) {
    await import('@formatjs/intl-relativetimeformat/polyfill-force');
    await import(
      /* webpackInclude: /(en|zh|ja|ko)\.js$/, webpackChunkName: "relativetimeformat-locale-data.[request]" */
      `@formatjs/intl-relativetimeformat/locale-data/${unsupportedRelativetimeformatLocale}`
    );
  }
};

export const formatTranslationMessages = (lang, messages) => {
  const defaultFormattedMessages =
    lang !== DEFAULT_LANG
      ? formatTranslationMessages(DEFAULT_LANG, enTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && lang !== DEFAULT_LANG) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

// default set of languages
export const translationMessages = {
  [LANG.EN]: formatTranslationMessages(LANG.EN, enTranslationMessages),
  [LANG.ZH_HANT]: formatTranslationMessages(
    LANG.ZH_HANT,
    zhTWTranslationMessages
  ),
};
