import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

let fpVisitor: GetResult;

export const fingerprintInit = async () => {
  if (fpVisitor) return fpVisitor;

  const fpAgent = await FingerprintJS.load();

  fpVisitor = await fpAgent.get();

  return fpVisitor;
};

export const getFingerprintVisitor = () => fpVisitor ?? {};
