import { QueryOptions } from 'react-query';

import { RegionType } from '@17live/app/constants';
import { useRegion } from '@17live/app/containers/LanguageProvider/hooks';
import { useFetch } from '@17live/app/hooks';
import { getUseFetchConfigQueryKey } from '@17live/app/hooks/apis/useFetchConfig.utils';
import { useImmutableSelector } from '@17live/app/hooks/useSelector';
import { Config } from '@17live/app/types/Config';

export type UseFetchConfigOptions = Partial<{
  isPersonal: boolean;
  overrideRegion?: RegionType;
}>;

export const useFetchConfig = <ReturnData = Config>(
  options: QueryOptions<ReturnData> & UseFetchConfigOptions = {}
) => {
  const { isPersonal, overrideRegion, ...useQueryOptions } = options;

  const loginUserID: string = useImmutableSelector(s =>
    s.getIn(['login', 'userInfo', 'userID'])
  );

  const { loginUserRegion, region: webRegion } = useRegion();

  const userSelectedRegion = overrideRegion || loginUserRegion;

  const headers = {
    userSelectedRegion,
  };

  if (!loginUserID) {
    Object.assign(headers, { 'force-region': webRegion });
  }

  return useFetch<ReturnData>(
    getUseFetchConfigQueryKey({
      region: userSelectedRegion,
      userID: loginUserID,
      isPersonal,
    }),
    {
      headers,
    },
    useQueryOptions
  );
};
