import { createGCSImage } from './createGCSImage';

export const getGCSImage = createGCSImage<
  | '17-error-sprite.png'
  | 'ig-qr-code-2021.png'
  | 'ig-qr-code.png' // 舊的有寶寶圖案的 QRCode，目前暫時不把 GCS 上舊的圖刪掉，所以先保留這個 Type
  | 'game_v2.gif'
  | 'ig_achievement_value.gif'
  | 'loading-sprite.png'
  | 'ig_17live_round.png'
  | 'gift-hero-bg.webp'
  | 'Vliver_FAQ_picV_format.png'
  | 'ig_Vliver_FAQ_picV_OK.png'
  | 'ig_Vliver_FAQ_picV_NG.png'
  | 'ig_Vliver_image_tiips1_Deco.png'
  | 'ig_Vliver_image_tiips2_hair.png'
  | 'ig_Vliver_image_tiips3_eye.png'
  | 'ig_Vliver_image_tiips4_face.png'
  | 'ig_Vliver_image_tiips5_mouth.png'
  | 'ig_promocode.png'
  | 'ig-empty-banner.svg'
  | 'Vliver_FAQ_concept.png'
  | 'Vliver_FAQ_detection.png'
>(['images']);

export const getRisingStarImage = createGCSImage<
  | 'deco-lv-landspace-default.png'
  | 'deco-lv-landspace-final.png'
  | 'ig-placehoder-error.png'
  | 'ig-tourist-daily-task.png'
  | 'ig-tourist-daily-task-step.png'
>(['risingStar']);

export const getArmyImage = createGCSImage<
  'ig_army_kv.png' | 'ig_army_footer_large.png' | 'ig_army_footer_small.png'
>(['army', 'img']);

export type ArmyIconImageType =
  | 'ig-idol-1-s.png'
  | 'ig-idol-1.png'
  | 'ig-idol-2-s.png'
  | 'ig-idol-2.png'
  | 'ig-idol-3-s.png'
  | 'ig-idol-3.png'
  | 'ig-idol-4-s.png'
  | 'ig-idol-4.png'
  | 'ig-idol-5-s.png'
  | 'ig-idol-5.png';

export type NewArmyIconImageType =
  | 'ig_army_sergeant.png'
  | 'ig_army_captain.png'
  | 'ig_army_colonel.png'
  | 'ig_army_general.png'
  | 'ig_army_corporal.png'
  | 'ig_army_more.png';

export const getArmyIconImage = createGCSImage<
  ArmyIconImageType | NewArmyIconImageType
>(['army', 'icon']);

export const getPurchaseLogoImage = createGCSImage<
  | 'ig-iap-newwbpay.png'
  | 'ig-iap-ecpay.png'
  | 'ig-iap-jkopay.png'
  | 'ig-iap-mycard.png'
  | 'ig-iap-gashpoint.png'
  | 'ig-iap-smbcgmo.png'
  | 'ig-iap-paidy.png'
  | 'ig-iap-firstdata.png'
  | 'ig-iap-stripe.png'
  | 'ig-iap-payermax.png'
  | 'paidy_logo_3x_2021 2.png'
  | 'ig_payment_default.png'
  | 'ig_payment_paidy.png'
>(['purchase']);

export type CheckingLevelImageType =
  | ''
  | 'ig-bg-checking-green.png'
  | 'ig-bg-checking-blue.png'
  | 'ig-bg-checking-purple.png'
  | 'ig-bg-checking-orange.png'
  | 'ig-bg-checking-silver.png'
  | 'ig-bg-checking-golden.png'
  | 'ig-bg-checking-black.png';

export const getCheckingLevelImage = createGCSImage<CheckingLevelImageType>([
  'checking',
]);

export type BadgeImageType =
  | 'ig-corporal-badge.png'
  | 'ig-sergeant-badge.png'
  | 'ig-captain-badge.png'
  | 'ig-colonel-badge.png'
  | 'ig-general-badge.png'
  | 'ig-guardian-badge.png'
  | 'ig-firstrank-badge.png';

export const getBadgeImage = createGCSImage<BadgeImageType>(['badge']);

export type IncentiveImageType =
  | 'icn_streamer_rank_R1.png'
  | 'icn_streamer_rank_R2.png'
  | 'icn_streamer_rank_R3.png'
  | 'icn_streamer_rank_R4.png'
  | 'icn_streamer_rank_R5.png'
  | 'img_FAQ_fan%20exp.png'
  | 'img_FAQ_superfan%20lvl.png'
  | 'img_FAQ_fanbadge_1@3x.png'
  | 'img_FAQ_fanbadge_2@3x.png'
  | 'img_streamer_info_energy_point.png'
  | 'img_streamer_info_revenue.png'
  | 'img_streamer_info_spotlight.png'
  | 'img_streamer_decayenergy.png'
  | 'img_info_streamer_spotlight_time.png'
  | 'ig_alert_superfanupgrade_lvl1.png'
  | 'ig_alert_superfanupgrade_lvl2.png'
  | 'ig_alert_superfanupgrade_lvl3.png'
  | 'ig_alert_superfanupgrade_lvl4.png'
  | 'ig_alert_superfanupgrade_lvl5.png'
  | 'ig_alert_streamer_upgrade_ph1.png'
  | 'ig_alert_streamer_upgrade_ph2.png'
  | 'ig_alert_streamer_upgrade_ph3.png'
  | 'ig_alert_streamer_upgrade_ph4.png'
  | 'ig_alert_streamer_upgrade_ph5.png'
  | 'ig_viewer_level_bg__lvl1.png'
  | 'ig_viewer_level_bg__lvl2.png'
  | 'ig_viewer_level_bg__lvl3.png'
  | 'ig_viewer_level_bg__lvl4.png'
  | 'ig_viewer_level_bg__lvl5.png'
  | 'Gift_hero_top_hero.png'
  | 'Gift_hero_top_hero_empty.png'
  | 'icn_new_viewer.png'
  | 'icn_spotlight.png';

export const getIncentiveImage = createGCSImage<IncentiveImageType>([
  'incentive',
]);

export const getSnackImage = createGCSImage(['snack']);
