// @flow

// localStorage
export const KEY_PAY_FROM = '17LIVE/KEY_PAY_FROM';

export const KEY_HISTORY_LIVE_STREAMS: string = '17LIVE/HISTORY_LIVE_STREAMS';
export const KEY_LOGIN_INFO: string = '17LIVE/LOGIN_INFO';
export const KEY_PREFERENCES: string = '17LIVE/KEY_PREFERENCES';
export const KEY_SELECTED_VENDOR = '17LIVE/KEY_SELECTED_VENDOR';
export const KEY_SELECTED_VENDOR_FOR_TW = '17LIVE/KEY_SELECTED_VENDOR_FOR_TW';

export const KEY_GO_LIVE_FIRST = '17live/KEY_GO_LIVE_FIRST';
export const KEY_BARRAGE_FIRST_INTRO = '17live/KEY_BARRAGE_FIRST_INTRO';
export const KEY_RED_ENVELOPE_FIRST_INTRO =
  '17live/KEY_RED_ENVELOPE_FIRST_INTRO';

export const KEY_GROUP_CALL_FIRST_INTRO = '17LIVE/KEY_GROUP_CALL_FIRST_INTRO';
export const KEY_ARCHIVE_CONFIRMED = '17LIVE/KEY_ARCHIVE_CONFIRMED';
export const KEY_TOUR_VLIVER_UPLOAD = '17LIVE/KEY_TOUR_VLIVER_UPLOAD';
// vliver info value in live stream setting.
export const KEY_SETTING_VLIVER_INFO = '17LIVE/KEY_SETTING_VLIVER_INFO';

// Preference key
export const PREFERENCE_KEY_INCENTIVE = 'incentive';
export const PREFERENCE_KEY_MY_REVENUE = 'myRevenue';

// Contract application
export const KEY_CONTRACT_APPLICATION_STEP1_INFO =
  '17LIVE/KEY_CONTRACT_APPLICATION_STEP1_INFO';
export const KEY_CONTRACT_APPLICATION_STEP2_INFO =
  '17LIVE/KEY_CONTRACT_APPLICATION_STEP2_INFO';

// 17Shop
export const KEY_17SHOP_POST_ID = '17LIVE/KEY_17SHOP_POST_ID';
export const KEY_SETTING_IS_SHOW_PRODUCTS =
  '17LIVE/KEY_SETTING_IS_SHOW_PRODUCTS';

// sessionStorage
export const KEY_EVENTS = '17live/KEY_EVENTS';

// dev tools
export const KEY_DEV_IS_ENABLED: string = '17LIVE/KEY_DEV_IS_ENABLED';
export const KEY_DEV_BACKEND_SERVER: string = '17LIVE/KEY_DEV_BACKEND_SERVER';
export const KEY_DEV_MESSAGE_PROVIDER: string =
  '17LIVE/KEY_DEV_MESSAGE_PROVIDER';
export const KEY_DEV_GLOBAL_MESSAGE_PROVIDER =
  '17LIVE/KEY_DEV_GLOBAL_MESSAGE_PROVIDER';
export const KEY_DEV_PUBNUB_SERVER: string = '17LIVE/KEY_DEV_PUBNUB_SERVER';
export const KEY_DEV_ABLY_SERVER: string = '17LIVE/KEY_DEV_ABLY_SERVER';

export const KEY_TRACKING = '17LIVE/KEY_TRACKING';
export const KEY_BRAZE_CUSTOM_EVENTS_IS_ENABLED_IN_STAGE =
  '17LIVE/KEY_BRAZE_CUSTOM_EVENTS_IS_ENABLED_IN_STAGE';

// Remove localstorage items when logout
export const REMOVE_WHEN_LOGOUT_KEYS = [
  // to ensure the other logout epics will synchronously emit first
  KEY_LOGIN_INFO,
  // According to Archive spec APP-47289: Record in client side, login/logout will clear the data and users have to agree again. (by device and it will clear while logout)
  KEY_ARCHIVE_CONFIRMED,
];

export const KEY_LANDINGPAGE_RECORD = '17LIVE/KEY_LANDINGPAGE_RECORD';

export const KEY_GIFT_BOX_HEADER_TOOLTIP = '17LIVE/KEY_GIFT_BOX_HEADER_TOOLTIP';

// APP-99941: Improved guidance from share links to the app
export const KEY_GUIDANCE_FROM_SHARE_LINK =
  '17LIVE/KEY_GUIDANCE_FROM_SHARE_LINK';
export const KEY_IS_SHARE_LINK = '17LIVE/KEY_IS_SHARE_LINK';
